@media (max-width: 1520px) {
  /* INTRO PAGE */
  /* S:: 2-1 온강 소개 */
  .intro .banner-main {
    background: url("/src/resources/image/intro/intro-banner.png") no-repeat 50%
      center / calc(100vw * (1920 / 1520)) calc(100vw * (660 / 1520)) #000;
    height: calc(100vw * (660 / 1520));
    padding-top: calc(100vw * (80 / 1520));
  }
  .intro .banner-main .text-wrap .tit {
    font-size: calc(100vw * (62 / 1520));
    line-height: calc(100vw * (44 / 1520));
  }
  .intro .banner-main .text-wrap .tit .line-img {
    max-width: calc(100vw * (58 / 1520));
    height: 3px;
    margin-right: calc(100vw * (20 / 1520));
  }
  .intro .banner-main .text-wrap .heading-text {
    gap: calc(100vw * (30 / 1520));
  }
  .intro .banner-main .text-wrap .heading-text .sub-title {
    font-size: calc(100vw * (16 / 1520));
    line-height: calc(100vw * (24 / 1520));
    letter-spacing: -2%;
    white-space: pre;
  }

  .intro .intro-wrap .intro-top {
    height: calc(100vw * (651 / 1520));
    background: url("/src/resources/image/intro/intro-bg.png") no-repeat top /
      cover;
    padding-top: calc(100vw * (164 / 1520));
    margin-bottom: calc(100vw * (200 / 1520));
  }
  .intro .intro-wrap .member-img {
    max-width: calc(100vw * (1260 / 1520));
  }

  .intro .intro-wrap .intro-bottom {
    gap: calc(100vw * (90 / 1520));
  }
  .intro .intro-wrap .intro-bottom .brand-logo {
    max-width: calc(100vw * (292 / 1520));
  }
  .intro .intro-wrap .intro-bottom .summary {
    gap: calc(100vw * (50 / 1520));
  }

  .intro .intro-wrap .intro-bottom .summary .typo-img {
    max-width: calc(100vw * (646 / 1520));
  }
  .intro .intro-wrap .intro-bottom .summary .text-wrap h3 {
    font-size: calc(100vw * (36 / 1520));
    line-height: 140%;
    margin-bottom: calc(100vw * (30 / 1520));
  }
  .intro .intro-wrap .intro-bottom .summary .text-wrap h4 {
    font-size: calc(100vw * (18 / 1520));
    line-height: 160%;
    margin-bottom: calc(100vw * (28 / 1520));
  }
  /* E:: 2-1 온강 소개 */

  /* S:: 2-2 온강의 특별함 */
  .special-banner {
    height: calc(100vw * (850 / 1520));
  }
  .special-banner .text-wrap h3 {
    font-size: calc(100vw * (66 / 1520));
    margin: calc(100vw * (30 / 1520)) 0 calc(100vw * (40 / 1520)) 0;
  }
  .special-banner .text-wrap h4 {
    font-size: calc(100vw * (26 / 1520));
    line-height: calc(100vw * (31 / 1520));
  }
  .special-banner .text-wrap h4.bottom {
    line-height: calc(100vw * (36 / 1520));
  }
  .special-banner .text-wrap h4 .high-light {
    line-height: calc(100vw * (31 / 1520));
  }

  .special-reason {
    margin-bottom: calc(100vw * (100 / 1520));
  }
  .reason-list {
    padding-top: calc(100vw * (80 / 1520));
  }
  .reason-item .item-wrap {
    gap: calc(100vw * (14 / 1520));
  }
  .reason-item .special-step-img {
    width: calc(100vw * (590 / 1520));
    height: calc(100vw * (400 / 1520));
  }
  .reason-item:nth-child(2) .item-wrap {
    margin-bottom: calc(100vw * (50 / 1520));
  }
  .reason-item:nth-child(2) .heading {
    margin-bottom: calc(100vw * (40 / 1520));
  }
  .reason-item:nth-child(2) .special-step-img {
    width: calc(100vw * (1009 / 1520));
    /* height: calc(100vw * (1165 / 1520)); */
    height: auto;
  }
  .reason-item + .reason-item {
    margin-top: calc(100vw * (60 / 1520));
    padding-top: calc(100vw * (60 / 1520));
  }
  .reason-item .count-num {
    width: calc(100vw * (60 / 1520));
    height: calc(100vw * (60 / 1520));
    font-size: calc(100vw * (36 / 1520));
  }
  .reason-item .heading {
    font-size: calc(100vw * (36 / 1520));
    margin-bottom: calc(100vw * (20 / 1520));
  }
  .reason-item .sub-text {
    font-size: calc(100vw * (18 / 1520));
  }
  .reason-item .img-list {
    height: calc(100vw * (375 / 1520));
    margin-top: calc(100vw * (50 / 1520));
    transform: translateX(calc(100vw * (10 / 1520)));
  }
  .reason-item .img-item {
    margin-left: calc(100vw * (-75 / 1520));
  }

  .reason-item .lecture-box {
    margin: calc(100vw * (40 / 1520)) auto 0 auto;
  }

  .reason-item.bg {
    background: url("/src/resources/image/intro/special-bg.png") no-repeat
      center / calc(100vw * (1920 / 1520)) calc(100vw * (865 / 1520));
    margin-top: -30px;
  }
  .reason-item .counselor-img {
    margin: calc(100vw * (50 / 1520)) auto 0 auto;
  }
  .reason-item .hair-img {
    margin: calc(100vw * (50 / 1520)) auto 0 auto;
  }
  /* E:: 2-2 온강의 특별함 */

  /* S:: 2-3 전문가 소개 */

  .inner.expert .expert-banner {
    height: calc(100vw * (961 / 1520));
  }
  .inner.expert .expert-banner-inner {
    width: calc(100vw * (2186 / 1520));
    height: calc(100vw * (1173 / 1520));
  }
  .banner-sw {
    height: calc(100vw * (961 / 1520));
    max-width: calc(100vw * (1920 / 1520));
  }
  .banner-sw .swiper .swiper-slide .img-box {
    width: calc(100vw * (574 / 1520));
    height: calc(100vw * (741 / 1520));
  }
  .banner-sw .text-inner {
    bottom: calc(100vw * (170 / 1520));
    width: calc(100vw * (450 / 1520));
  }
  .banner-sw .text-inner .title {
    font-size: calc(100vw * (36 / 1520));
    margin-bottom: calc(100vw * (15 / 1520));
  }
  .banner-sw .text-inner .sub-title {
    font-size: calc(100vw * (18 / 1520));
    margin-bottom: calc(100vw * (30 / 1520));
  }
  .banner-sw .text-inner .sub-title img {
    width: calc(100vw * (21 / 1520));
    height: calc(100vw * (21 / 1520));
  }

  .expert-search {
    padding-top: calc(100vw * (80 / 1520));
    margin-bottom: calc(100vw * (40 / 1520));
  }
  .expert-list {
    gap: calc(100vw * (20 / 1520));
    row-gap: calc(100vw * (50 / 1520));
    padding-bottom: calc(100vw * (70 / 1520));
  }

  .expert-item {
    width: calc(100vw * (300 / 1520));
    height: calc(100vw * (448 / 1520));
  }
  .expert-item::after {
    content: "";
    border: calc(100vw * (5 / 1520)) solid transparent;
    box-sizing: border-box;
    transition: 0.2s;
    pointer-events: none;
  }
  .expert-item .text-wrap {
    left: calc(100vw * (30 / 1520));
    bottom: calc(100vw * (40 / 1520));
  }
  .expert-item .text-wrap::before {
    top: calc(100vw * (-9 / 1520));
    width: calc(100vw * (50 / 1520));
    height: calc(100vw * (3 / 1520));
  }
  .expert-item .logo-box-wrap {
    gap: calc(100vw * (5 / 1520));
    margin-bottom: calc(100vw * (4 / 1520));
  }
  .expert-item .title {
    font-size: calc(100vw * (20 / 1520));
    line-height: calc(100vw * (24 / 1520));
  }
  .expert-item .sub-title {
    font-size: calc(100vw * (16 / 1520));
    line-height: calc(100vw * (19 / 1520));
  }

  .expert-item .logo-box {
    width: calc(100vw * (28 / 1520));
    max-width: calc(100vw * (28 / 1520));
    height: calc(100vw * (28 / 1520));
  }

  /* empty list */
  .empty-list {
    margin-top: calc(100vw * (40 / 1520));
    padding: calc(100vw * (213 / 1520)) 0;
    font-size: calc(100vw * (18 / 1520));
    line-height: calc(100vw * (21 / 1520));
  }
  .expert-detail:not(.example) {
    height: calc(100vw * (1004 / 1520));
    background: url("/src/resources/image/intro/expert-bg.jpg") no-repeat center /
      calc(100vw * (1920 / 1520)) calc(100vw * (1004 / 1520));
  }
  .expert-detail:not(.example)::after {
    content: "";
    width: calc(100vw * (1920 / 1520));
    height: calc(100vw * (1004 / 1520));
    background: url("/src/resources/image/intro/expert-line-bg.png") no-repeat
      right / calc(100vw * (1036 / 1520)) calc(100vw * (1004 / 1520));
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .expert-detail__inner .text-wrap {
    width: calc(100vw * (520 / 1520));
    padding-top: calc(100vw * (120 / 1520));
  }
  .expert-detail__inner .text-wrap .logo-img {
    width: calc(100vw * (53 / 1520));
    margin-right: calc(100vw * (5 / 1520));
  }

  .expert-detail .img-box img {
    max-width: calc(100vw * (706 / 1520));
  }

  .expert-detail .text-wrap .bottom {
    margin-bottom: calc(100vw * (50 / 1520));
  }
  .expert-detail .text-wrap .badge {
    font-size: calc(100vw * (18 / 1520));
    line-height: calc(100vw * (21 / 1520));
    padding: calc(100vw * (6 / 1520)) calc(100vw * (30 / 1520));
    margin-bottom: calc(100vw * (20 / 1520));
  }
  .expert-detail .name-wrap {
    margin-bottom: calc(100vw * (40 / 1520));
  }
  .expert-detail .name-wrap .title {
    font-size: calc(100vw * (62 / 1520));
    letter-spacing: calc(100vw * (20 / 1520));
  }
  .expert-detail .name-wrap .sub-title {
    font-size: calc(100vw * (36 / 1520));
  }

  .expert-detail .scroll-item {
    gap: calc(100vw * (30 / 1520));
  }
  .scroll-item + .scroll-item {
    margin-top: calc(100vw * (50 / 1520));
  }
  .expert-detail .scroll-item .category {
    font-size: calc(100vw * (36 / 1520));
  }
  .expert-detail .resume-item {
    padding-left: calc(100vw * (11 / 1520));
  }
  .expert-detail .resume-item::before {
    content: "";
    display: block;
    width: calc(100vw * (5 / 1520));
    height: calc(100vw * (5 / 1520));
    border-radius: 50%;
    margin-right: calc(100vw * (10 / 1520));
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .resume-item + .resume-item {
    margin-top: calc(100vw * (12 / 1520));
  }

  .expert-detail.example .example-inner {
    max-width: calc(100vw * (1500 / 1520));
    margin-top: calc(100vw * (80 / 1520));
  }
  .expert-detail.example .example-inner .head .title {
    font-size: calc(100vw * (36 / 1520));
    line-height: calc(100vw * (43 / 1520));
  }
  .expert-detail.example .example-inner .swiper_wrap .swiper {
    padding: calc(100vw * (30 / 1520)) calc(100vw * (120 / 1520))
      calc(100vw * (70 / 1520)) calc(100vw * (120 / 1520));
  }
  .expert-detail.example .example-inner .slide_btn {
    transform: translate(0, -50%);
  }
  .expert-detail.example .btn-box {
    padding-top: calc(100vw * (70 / 1520));
  }
  .expert-detail.example .btn-box .btn {
    border-radius: calc(100vw * (50 / 1520));
    padding: calc(100vw * (17 / 1520)) 0;
    max-width: calc(100vw * (560 / 1520));
  }

  /* E:: 2-3 전문가 소개 */

  /* S:: 2-4 언론보도 */
  .expert-column-wrap .column-inner {
    padding-top: calc(100vw * (120 / 1520));
  }
  .expert-column-wrap .column-inner .top {
    padding-bottom: calc(100vw * (30 / 1520));
  }
  /* .expert-column-wrap .column-inner .bottom {padding-top: calc(100vw*(80 / 1520));} */
  /* E:: 2-4 언론보도 */

  /* S:: 2-4 언론보도 - 에디트 */
  .expert-editor-wrap {
    padding-bottom: calc(100vw * (70 / 1520));
    margin-bottom: calc(100vw * (70 / 1520));
  }
  .expert-editor-wrap .editor-head {
    padding: calc(100vw * (120 / 1520)) calc(100vw * (20 / 1520))
      calc(100vw * (50 / 1520));
    margin-bottom: calc(100vw * (50 / 1520));
  }
  .expert-editor-wrap .editor-head .head-inner {
    margin: 0 calc(100vw * (10 / 1520));
  }
  .expert-editor-wrap .editor-head .float-type {
    font-size: calc(100vw * (18 / 1520));
    padding: calc(100vw * (6 / 1520)) calc(100vw * (30 / 1520));
    margin-bottom: calc(100vw * (30 / 1520));
  }
  .expert-editor-wrap .editor-head .head-box {
    gap: calc(100vw * (20 / 1520));
  }
  .expert-editor-wrap .editor-head .title {
    font-size: calc(100vw * (24 / 1520));
    margin-bottom: calc(100vw * (24 / 1520));
  }
  .expert-editor-wrap .editor-head .img-box {
    max-width: calc(100vw * (246 / 1520));
  }
  .expert-editor-wrap .editor-box {
    min-height: calc(100vw * (400 / 1520));
  }
  .other-list .btn-box {
    padding-top: calc(100vw * (70 / 1520));
  }
  .other-list .btn-box .btn {
    border-radius: calc(100vw * (50 / 1520));
    padding: calc(100vw * (17 / 1520)) 0;
    max-width: calc(100vw * (560 / 1520));
  }

  /* E:: 2-4 언론보도 - 에디트 */

  /* S:: 2-5 오시는 길 */
  .expert-map {
    padding-top: calc(100vw * (140 / 1520));
    min-height: calc(100vw * (1000 / 1520));
  }
  .expert-map::before {
    content: "";
    display: block;
    width: calc(100vw * (470 / 1520));
    height: calc(100vw * (570 / 1520));
    left: 0;
    background: url("/src/resources/image/intro/map_bg.jpg") no-repeat center /
      calc(100vw * (470 / 1520)) calc(100vw * (570 / 1520));
    transform: translateY(calc(100vw * (-160 / 1520)));
  }
  .expert-map .map-contents {
    min-height: calc(100vw * (650 / 1520));
    margin-bottom: calc(100vw * (20 / 1520));
  }
  .expert-map .map-item .map-item-inner {
    font-size: calc(100vw * (20 / 1520));
    line-height: calc(100vw * (24 / 1520));
    padding: calc(100vw * (20 / 1520)) 0;
  }
  .expert-map .map-info-item {
    padding: calc(100vw * (40 / 1520)) 0;
  }
  .expert-map .map-info-item .map-text {
    gap: calc(100vw * (40 / 1520));
    font-size: calc(100vw * (20 / 1520));
    line-height: calc(100vw * (24 / 1520));
  }
  .expert-map .map-info-item .map-text .title {
    min-width: calc(100vw * (70 / 1520));
  }
  .expert-map .map-info-item .map-text:last-of-type {
    max-width: calc(100vw * (520 / 1520));
  }
  /* E:: 2-5 오시는 길 */

  /* S:: 6-1 의뢰인 후기 */
  .review-wrap {
    padding-top: calc(100vw * (120 / 1520));
  }
  .review-wrap .review-top {
    padding-bottom: calc(100vw * (50 / 1520));
  }
  /* 상세 */
  .edit.story .head-box {
    padding-top: calc(100vw * (30 / 1520));
  }
  /* E:: 6-1 의뢰인 후기 */

  /* S:: 6-2 유튜브 */
  .youtube-wrap {
    padding-top: calc(100vw * (120 / 1520));
  }
  .youtube-wrap .youtube-top {
    padding-bottom: calc(100vw * (80 / 1520));
  }
  .youtube-wrap .best-wrap {
    gap: calc(100vw * (50 / 1520));
    padding-top: calc(100vw * (30 / 1520));
  }
  .youtube-wrap .best-wrap .img-box-container {
    max-width: calc(100vw * (640 / 1520));
  }
  .youtube-wrap .best-wrap .img-box {
    width: calc(100vw * (640 / 1520));
    height: calc(100vw * (405 / 1520));
  }
  .youtube-wrap .text-wrap .title {
    font-size: calc(100vw * (24 / 1520));
    margin-bottom: calc(100vw * (30 / 1520));
  }
  .youtube-wrap .text-wrap .sub-title {
    font-size: calc(100vw * (16 / 1520));
    line-height: 140%;
    margin-bottom: calc(100vw * (50 / 1520));
  }
  .youtube-wrap .youtube-bottom {
    max-width: calc(100vw * (1500 / 1520));
    padding: calc(100vw * (80 / 1520)) calc(100vw * (10 / 1520)) 0;
  }
  .category-item + .category-item {
    margin-top: calc(100vw * (80 / 1520));
  }
  /* E:: 6-2 유튜브 */

  /* S:: 6-3 컬럼 */
  .story-column-wrap .column-inner {
    padding-top: calc(100vw * (120 / 1520));
  }
  .story-column-wrap .column-inner .top {
    padding-bottom: calc(100vw * (30 / 1520));
    margin-bottom: calc(100vw * (40 / 1520));
  }
  .story-column-wrap .column-inner .bottom {
    padding-top: calc(100vw * (80 / 1520));
  }
  .story-column-wrap .column-inner .bottom .column-list-container {
    padding-top: calc(100vw * (40 / 1520));
  }
  /* E:: 6-3 컬럼 */

  /* E:: 4 상활별 대응 전략 */
  .page .situation .text-wrap .heading-text {
    margin-top: calc(100vw * (-20 / 1520));
    white-space: pre;
  }
  .page .situation .text-wrap .heading-text .sub-title {
    display: none;
  }
  .page .banner-main.medication {
    height: calc(100vw * (660 / 1520));
  }
  .page .banner-main.sales {
    height: calc(100vw * (660 / 1520));
  }
  .page .banner-main.smuggling {
    height: calc(100vw * (660 / 1520));
  }
  .page .banner-main.arrest {
    height: calc(100vw * (660 / 1520));
  }

  /* 1번째 직통 상담 섹션 */
  .situation .apply-inner {
    height: calc(100vw * (944 / 1520));
    background: url("/src/resources/image/situation/situation-bg.jpg") no-repeat
      center calc(100vw * (1 / 1520)) / calc(100vw * (1920 / 1520))
      calc(100vw * (944 / 1520)) #000;
    margin-bottom: calc(100vw * (20 / 1520));
  }
  .situation .contents-wrap {
    padding: calc(100vw * (120 / 1520)) calc(100vw * (10 / 1520)) 0;
  }
  .situation .info-box {
    height: calc(100vw * (500 / 1520));
  }
  .situation .info-box .text-wrap {
    width: 50%;
    padding-left: calc(100vw * (40 / 1520));
  }
  .situation .info-box .title {
    font-size: calc(100vw * (36 / 1520));
    padding-bottom: calc(100vw * (24 / 1520));
    margin-bottom: calc(100vw * (24 / 1520));
  }
  .situation .info-box .sub-title {
    font-size: calc(100vw * (20 / 1520));
    line-height: calc(100vw * (28 / 1520));
  }
  .situation .info-box .sub-title:last-of-type {
    margin-bottom: calc(100vw * (30 / 1520));
  }
  .situation .step-box {
    font-size: calc(100vw * (20 / 1520));
    gap: calc(100vw * (20 / 1520));
  }
  .situation .step-box .step {
    gap: calc(100vw * (10 / 1520));
  }

  .situation .bottom-info {
    padding-top: calc(100vw * (60 / 1520));
  }
  .situation .bottom-info .title {
    font-size: calc(100vw * (36 / 1520));
    margin-bottom: calc(100vw * (15 / 1520));
  }
  .situation .bottom-info .sub-title {
    font-size: calc(100vw * (18 / 1520));
    margin-bottom: calc(100vw * (30 / 1520));
  }
  .situation .bottom-info .btn {
    max-width: calc(100vw * (560 / 1520));
    padding: calc(100vw * (17 / 1520)) 0;
    font-size: calc(100vw * (18 / 1520));
    border-radius: calc(100vw * (50 / 1520));
  }

  .situation .focus-inner .contents-wrap {
    padding-top: calc(100vw * (80 / 1520));
    padding-bottom: calc(100vw * (80 / 1520));
  }
  /* S:: situation common */
  .situation .head-title {
    font-size: calc(100vw * (36 / 1520));
    line-height: calc(100vw * (54 / 1520));
    margin-bottom: calc(100vw * (50 / 1520));
  }
  .situation .head-title .sub-title {
    font-size: calc(100vw * (26 / 1520));
  }
  /* E:: situation common */

  .situation .img-step-list {
    gap: calc(100vw * (20 / 1520));
    margin-bottom: calc(100vw * (30 / 1520));
  }
  .situation .img-step-item {
    max-width: calc(100vw * (620 / 1520));
    padding: calc(100vw * (18 / 1520));
  }
  .situation .img-step-item .top {
    margin-bottom: calc(100vw * (58 / 1520));
  }
  .situation .img-step-item .badge {
    font-size: calc(100vw * (26 / 1520));
    padding: calc(100vw * (7 / 1520)) calc(100vw * (55 / 1520));
  }
  .situation .focus-inner .bottom .text {
    font-size: calc(100vw * (20 / 1520));
    line-height: calc(100vw * (28 / 1520));
  }

  .situation .focus-inner .way-text .title {
    font-size: calc(100vw * (36 / 1520));
    line-height: calc(100vw * (54 / 1520));
  }
  .situation .focus-inner .gradient-line {
    width: calc(100vw * (3 / 1520));
    height: calc(100vw * (70 / 1520));
    margin-bottom: calc(100vw * (10 / 1520));
  }
  .situation .focus-inner .way-text .sub-title {
    font-size: calc(100vw * (26 / 1520));
    margin-bottom: calc(100vw * (20 / 1520));
  }
  .situation .focus-inner .text-banner {
    padding: calc(100vw * (13 / 1520)) calc(100vw * (40 / 1520));
    font-size: calc(100vw * (36 / 1520));
    line-height: 140%;
  }

  .situation .process-inner {
    padding-top: calc(100vw * (80 / 1520));
    max-width: calc(100vw * (1920 / 1520));
  }
  .situation .process-item {
    height: calc(100vw * (850 / 1520));
    transition: 0.5s;
  }
  .situation .process-item .float-img-box {
    filter: brightness(0.5) grayscale(1);
    transition: 0.5s;
  }

  .situation .process-item .text-wrap {
    font-size: calc(100vw * (38 / 1520));
    margin-bottom: calc(100vw * (80 / 1520));
  }
  .situation .process-item .text-wrap .count {
    margin-bottom: calc(100vw * (20 / 1520));
  }
  .situation .process-item .text-wrap .title {
    margin-bottom: calc(100vw * (20 / 1520));
  }
  .situation .process-item:hover .text-wrap .more-btn {
    padding: calc(100vw * (12 / 1520)) calc(100vw * (30 / 1520));
  }

  .situation .process-item.fix-pop {
    z-index: 10;
  }

  .situation .fix-pop-inner img {
    max-width: calc(100vw * (800 / 1520));
  }
  .situation .fix-pop-inner .text-wrap {
    max-width: calc(100vw * (710 / 1520));
    margin: 0 0 0 calc(100vw * (80 / 1520));
  }
  .situation .fix-pop-inner .heading {
    gap: calc(100vw * (20 / 1520));
    padding-bottom: calc(100vw * (30 / 1520));
    margin-bottom: calc(100vw * (30 / 1520));
  }
  .situation .fix-pop-inner .heading .count {
    padding: calc(100vw * (5 / 1520)) calc(100vw * (13 / 1520));
    font-size: calc(100vw * (26 / 1520));
    line-height: calc(100vw * (31 / 1520));
    margin: 0;
  }
  .situation .fix-pop-inner .emphasize-box {
    padding: calc(100vw * (6 / 1520)) 0;
    margin-bottom: calc(100vw * (40 / 1520));
    font-size: calc(100vw * (14 / 1520));
  }
  .situation .fix-pop-inner .desc-list {
    gap: calc(100vw * (40 / 1520));
  }
  .situation .desc-item {
    gap: calc(100vw * (14 / 1520));
    font-size: calc(100vw * (20 / 1520));
  }
  .situation .desc-item .title-box {
    width: calc(100vw * (210 / 1520));
  }
  .situation .desc-item .sub-title-box {
    line-height: calc(100vw * (27 / 1520));
  }
  .situation .desc-item .sub-title-box p {
    font-size: calc(100vw * (18 / 1520));
  }
  .situation .desc-item .sub-title {
    gap: calc(100vw * (5 / 1520));
  }
  .situation .desc-item .in.sub-title {
    padding-left: calc(100vw * (35 / 1520));
  }

  .situation .fix-pop-inner .close-btn {
    z-index: 3;
    font-size: calc(100vw * (18 / 1520));
    transform: translate(calc(100vw * (-50 / 1520)), calc(100vw * (40 / 1520)));
    gap: calc(100vw * (5 / 1520));
  }

  .situation .dialog-inner {
    background: url("/src/resources/image/situation/dialog-bg.jpg") no-repeat
      center calc(100vw * (-1 / 1520)) / calc(100vw * (1920 / 1520))
      calc(100vw * (868 / 1520)) #fff;
    padding-top: calc(100vw * (80 / 1520));
  }
  .situation .dialog-inner .head-box {
    margin-bottom: calc(100vw * (80 / 1520));
  }
  .situation .dialog-container {
    padding: 0 calc(100vw * (10 / 1520));
  }
  .situation .dialog-box {
    width: calc(100vw * (628 / 1520));
    height: calc(100vw * (628 / 1520));
  }
  .situation .dialog-list {
    width: calc(100vw * (490 / 1520));
    height: calc(100vw * (490 / 1520));
  }

  .dialog-text .count {
    font-size: calc(100vw * (46 / 1520));
  }
  .dialog-text .title {
    font-size: calc(100vw * (30 / 1520));
  }

  .situation .dialog-box .dialog-logo {
    width: calc(100vw * (198 / 1520));
    height: calc(100vw * (198 / 1520));
  }
  .situation .dialog-box .dialog-logo img {
    width: calc(100vw * (70 / 1520));
  }
  .situation .triangle-img {
    width: calc(100vw * (50 / 1520));
  }

  .situation .dialog-inner .text-wrap {
    padding: 0 calc(100vw * (10 / 1520));
    margin: calc(100vw * (48 / 1520)) auto 0 auto;
  }
  .situation .dialog-inner .text-wrap .heading {
    font-size: calc(100vw * (26 / 1520));
    padding-bottom: calc(100vw * (30 / 1520));
    margin-bottom: calc(100vw * (30 / 1520));
  }

  .situation .dialog-inner .count-list {
    gap: calc(100vw * (40 / 1520));
  }
  .situation .dialog-inner .count-item .badge {
    font-size: calc(100vw * (26 / 1520));
    padding: calc(100vw * (5 / 1520)) calc(100vw * (13 / 1520));
    margin-bottom: calc(100vw * (10 / 1520));
  }
  .situation .dialog-inner .count-item .title {
    font-size: calc(100vw * (26 / 1520));
    margin-bottom: calc(100vw * (20 / 1520));
  }
  .situation .dialog-inner .count-item .sib-title {
    font-size: calc(100vw * (18 / 1520));
    line-height: calc(100vw * (27 / 1520));
  }
  .situation .dialog-inner .count-item .sib-title.emphasize {
    margin-top: calc(100vw * (30 / 1520));
    font-size: calc(100vw * (14 / 1520));
    line-height: normal;
  }
  /* E:: 4 상활별 대응 전략 */

  /* --------------------------------------- */
  /* --------------------------------------- */
  /* COMPONENT ----------------------------- */
  /* --------------------------------------- */

  /* S:: COMPONENT - TAB*/
  .history-navi-wrap .history-box {
    top: calc(100vw * (-20 / 1520));
  }
  .history-navi-wrap .history-box .history-list {
    gap: calc(100vw * (10 / 1520));
  }

  .history-navi-wrap .navi-box .navi-item {
    padding: calc(100vw * (30 / 1520)) 0;
  }
  .history-navi-wrap .navi-box .navi-item .link {
    font-size: calc(100vw * (18 / 1520));
    line-height: calc(100vw * (21 / 1520));
  }
  .history-navi-wrap .navi-box .navi-item .active.link::after {
    width: calc(100vw * (7 / 1520));
    height: calc(100vw * (7 / 1520));
    transform: translate(calc(100vw * (-17 / 1520)), -50%);
  }
  /* E:: COMPONENT - TAB*/

  /* S:: COMPONENT - PAGINATION */
  .pagination {
    margin-top: calc(100vw * (70 / 1520));
  }
  .pagination-btn {
    width: calc(100vw * (24 / 1520));
    height: calc(100vw * (24 / 1520));
    font-size: calc(100vw * (18 / 1520));
  }
  .pagination .pagination-num-box {
    gap: calc(100vw * (25 / 1520));
    margin: 0 calc(100vw * (40 / 1520));
  }
  .pagination .pagination-num-box .pagination-btn {
    width: auto;
    line-height: calc(100vw * (24 / 1520));
  }
  .pagination-arrow-box {
    gap: calc(100vw * (20 / 1520));
  }
  /* E:: COMPONENT - PAGINATION */

  /* S:: COMPONENT - SEARCH_INPUT */
  .search-box {
    border-radius: calc(100vw * (50 / 1520));
  }
  .search-input {
    padding: calc(100vw * (16 / 1520)) calc(100vw * (30 / 1520))
      calc(100vw * (15 / 1520)) calc(100vw * (30 / 1520));
  }
  .search-svg {
    padding-right: calc(100vw * (30 / 1520));
  }
  /* E:: COMPONENT - SEARCH_INPUT */
}

@media (max-width: 1024px) {
  .page {
    margin-bottom: calc(100vw * (130 / 375));
  }
  .intro .banner-main__inner {
    padding: 0 calc(100vw * (20 / 375));
  }

  .intro .banner-main {
    background: url("/src/resources/image/intro/intro-banner.png") no-repeat 45%
      center / auto calc(100vw * (360 / 375)) #000;
    height: calc(100vw * (360 / 375));
    padding-top: calc(100vw * (80 / 1520));
  }

  .intro .banner-main .text-wrap .tit {
    font-size: calc(100vw * (30 / 375));
    line-height: calc(100vw * (21 / 375));
  }
  .intro .banner-main .text-wrap .tit .line-img {
    max-width: calc(100vw * (30 / 375));
    height: 3px;
    margin-right: calc(100vw * (10 / 375));
  }
  .intro .banner-main .text-wrap .heading-text {
    gap: calc(100vw * (15 / 375));
  }
  .intro .banner-main .text-wrap .heading-text .sub-title {
    font-size: calc(100vw * (12 / 375));
    line-height: 150%;
    letter-spacing: -0.2px;
    white-space: break-spaces;
  }

  .intro .intro-wrap .intro-top {
    height: calc(100vw * (210 / 375));
    background: url("/src/resources/image/intro/intro-bg.png") no-repeat top /
      cover;
    padding-top: calc(100vw * (78 / 375));
    margin-bottom: calc(100vw * (55 / 375));
  }
  .intro .intro-wrap .member-img {
    max-width: calc(100vw * (335 / 375));
  }

  .intro .intro-wrap .intro-bottom {
    gap: calc(100vw * (20 / 375));
    flex-direction: column;
  }
  .intro .intro-wrap .intro-bottom .brand-logo {
    max-width: calc(100vw * (162 / 375));
  }
  .intro .intro-wrap .intro-bottom .summary {
    gap: calc(100vw * (30 / 375));
    align-items: center;
  }

  .intro .intro-wrap .intro-bottom .summary .typo-img {
    max-width: calc(100vw * (252 / 375));
  }
  .intro .intro-wrap .intro-bottom .summary .text-wrap h3 {
    font-size: calc(100vw * (26 / 375));
    margin-bottom: calc(100vw * (20 / 375));
  }
  .intro .intro-wrap .intro-bottom .summary .text-wrap h3 strong {
    display: block;
  }
  .intro .intro-wrap .intro-bottom .summary .text-wrap h4 {
    font-size: calc(100vw * (14 / 375));
    line-height: 160%;
    margin-bottom: calc(100vw * (22 / 375));
  }

  /* S:: 2-2 온강의 특별함 */
  .special-banner {
    height: calc(100vw * (536 / 375));
    background: url("/src/resources/image/intro/mb-intro-special-banner-total.jpg")
      no-repeat;
    background-size: 100% 100%;
  }
  .special-banner .text-wrap {
    padding: calc(100vw * (124 / 375)) calc(100vw * (28 / 375)) 0;
    justify-content: flex-start;
  }
  .special-banner .text-wrap h4 {
    font-size: calc(100vw * (20 / 375));
    line-height: calc(100vw * (24 / 375));
  }
  .special-banner .text-wrap h3 {
    font-size: calc(100vw * (40 / 375));
    margin: calc(100vw * (20 / 375)) 0 calc(100vw * (20 / 375)) 0;
  }
  .special-banner .text-wrap h4.bottom {
    line-height: calc(100vw * (24 / 375));
  }
  .special-banner .text-wrap h4 .high-light {
    line-height: calc(100vw * (31 / 375));
    border-bottom: 2px solid #fff;
    margin: 0 calc(100vw * (10 / 375)) 0 0;
  }

  .special-reason {
    margin-bottom: calc(100vw * (80 / 375));
  }
  .reason-list {
    padding-top: calc(100vw * (50 / 375));
  }
  .reason-item {
    padding: 0 calc(100vw * (20 / 375));
    flex-direction: column;
    gap: calc(100vw * (30 / 375));
    align-items: flex-start;
  }
  .reason-item:nth-child(even) {
    flex-direction: column;
  }
  .reason-item + .reason-item {
    margin-top: calc(100vw * (30 / 375));
    padding-top: calc(100vw * (30 / 375));
    width: calc(100vw - calc(100vw * (40 / 375)));
    padding-left: 0;
    padding-right: 0;
  }
  .reason-item .special-step-img {
    width: 100%;
    height: calc(100vw * (228 / 375));
  }
  .reason-item .item-wrap {
    flex-direction: column;
    gap: calc(100vw * (15 / 375));
  }
  .reason-item .count-num {
    width: calc(100vw * (40 / 375));
    height: calc(100vw * (40 / 375));
    font-size: calc(100vw * (26 / 375));
  }
  .reason-item .heading {
    font-size: calc(100vw * (26 / 375));
    word-wrap: break-word;
    word-break: keep-all;
    letter-spacing: -0.78px;
    margin-bottom: calc(100vw * (15 / 375));
  }
  .reason-item .sub-text {
    font-size: calc(100vw * (14 / 375));
    word-wrap: break-word;
    word-break: break-all;
  }
  .reason-item .img-list {
    width: calc(100vw * (421 / 375));
    height: auto;
    transform: translateX(0);
    display: flex;
    flex-wrap: wrap;
    padding-top: calc(100vw * (24 / 375));
    margin-top: calc(100vw * (30 / 375));
    margin-left: calc(100vw * (-38 / 375));
  }
  .reason-item .img-item {
    width: 33%;
    margin-left: calc(100vw * (-28 / 375));
    margin-top: calc(100vw * (-28 / 375));
  }
  .reason-item .lecture-box {
    height: calc(100vw * (198 / 375));
    margin: calc(100vw * (30 / 375)) auto 0 auto;
  }
  .reason-item .lecture-box img {
    height: 100%;
    object-fit: cover;
  }
  .reason-item.bg {
    background: url("/src/resources/image/intro/special-bg.png") no-repeat 22% -65px /
      auto calc(100vw * (865 / 375));
    margin-top: -30px;
  }
  .reason-item .inner-flex {
    flex-direction: column;
    padding: 0 calc(100vw * (20 / 375));
  }
  .reason-item .counselor-img {
    margin: calc(100vw * (50 / 375)) auto 0 auto;
  }
  .reason-item .hair-img {
    margin: calc(100vw * (40 / 375)) auto 0 auto;
  }
  .reason-item:nth-child(2) .item-wrap {
    flex-direction: column;
    margin-bottom: 0;
  }
  .reason-item:nth-child(2) .heading {
    margin-bottom: calc(100vw * (15 / 375));
  }
  .reason-item:nth-child(2) .special-step-img {
    width: calc(100vw * (334 / 375));
    /* height: calc(100vw * (1544 / 375)); */
    height: auto;
  }
  /* E:: 2-2 온강의 특별함 */

  /* S:: 2-3 전문가 소개 */

  .inner.expert .expert-banner {
    height: calc(100vw * (466 / 375));
  }
  .inner.expert .expert-banner-inner {
    width: calc(100vw * (626 / 375));
    height: 100%;
  }
  .banner-sw {
    height: 100%;
    max-width: calc(100vw * (375 / 375));
  }
  .banner-sw .swiper .swiper-slide .img-box {
    width: calc(100vw * (233 / 375));
    height: calc(100vw * (348 / 375));
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .banner-sw .text-inner {
    bottom: calc(100vw * (64 / 375));
    width: calc(100vw * (375 / 375));
    transform: none;
    left: calc(100vw * (20 / 375));
  }
  .banner-sw .text-inner .title {
    font-size: calc(100vw * (20 / 375));
    margin-bottom: calc(100vw * (10 / 375));
  }
  .banner-sw .text-inner .sub-title {
    font-size: calc(100vw * (14 / 375));
    margin-bottom: calc(100vw * (15 / 375));
  }
  .banner-sw .text-inner .sub-title img {
    width: calc(100vw * (17 / 375));
    height: calc(100vw * (17 / 375));
  }

  .section.expert {
    padding: 0;
  }
  .expert-wrap {
    padding: 0 calc(100vw * (20 / 375));
  }
  .expert-search {
    padding-top: calc(100vw * (45 / 375));
    margin-bottom: calc(100vw * (40 / 375));
  }
  .expert-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: calc(100vw * (15 / 375));
    row-gap: calc(100vw * (30 / 375));
    padding-bottom: calc(100vw * (40 / 375));
  }
  .expert-item {
    width: auto;
    height: calc(100vw * (239 / 375));
  }
  .expert-item::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: calc(100vw * (5 / 375)) solid transparent;
    box-sizing: border-box;
    transition: 0.2s;
    pointer-events: none;
  }
  .expert-item .text-wrap {
    left: calc(100vw * (10 / 375));
    bottom: calc(100vw * (15 / 375));
  }
  .expert-item .text-wrap::before {
    top: calc(100vw * (-9 / 375));
    width: calc(100vw * (50 / 375));
    height: calc(100vw * (3 / 375));
  }
  .expert-item .logo-box-wrap {
    gap: calc(100vw * (3 / 375));
    margin-bottom: calc(100vw * (4 / 375));
  }
  .expert-item .title {
    font-size: calc(100vw * (16 / 375));
    line-height: calc(100vw * (19 / 375));
  }
  .expert-item .sub-title {
    font-size: calc(100vw * (11 / 375));
    line-height: calc(100vw * (17 / 375));
  }

  .expert-item .logo-box {
    width: calc(100vw * (24 / 375));
    max-width: calc(100vw * (24 / 375));
    height: calc(100vw * (24 / 375));
  }

  /* empty list */
  .empty-list {
    margin-top: calc(100vw * (40 / 375));
    padding: calc(100vw * (213 / 375)) 0;
    font-size: calc(100vw * (18 / 375));
    line-height: calc(100vw * (21 / 375));
  }

  .expert-detail__inner {
    flex-direction: column;
  }
  .expert-detail:not(.example) {
    height: calc(100vw * (1004 / 375));
    background: url("/src/resources/image/intro/expert-bg.jpg") no-repeat center /
      calc(100vw * (1920 / 375)) calc(100vw * (1004 / 375));
    padding: 0 calc(100vw * (20 / 375));
  }
  .expert-detail .expert-detail__inner {
    padding: 0;
  }
  .expert-detail:not(.example)::after {
    content: "";
    width: calc(100vw * (1920 / 375));
    height: calc(100vw * (1004 / 375));
    background: url("/src/resources/image/intro/expert-line-bg.png") no-repeat
      center bottom / calc(100vw * (375 / 375)) calc(100vw * (535 / 375));
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .expert-detail__inner .text-wrap {
    width: 100%;
    padding-top: calc(100vw * (75 / 375));
  }
  .expert-detail__inner .text-wrap .logo-img {
    width: calc(100vw * (30 / 375));
    margin-right: calc(100vw * (5 / 375));
  }
  .expert-detail .img-box img {
    max-width: 100%;
  }
  .expert-detail .text-wrap .badge {
    font-size: calc(100vw * (16 / 375));
    line-height: calc(100vw * (21 / 375));
    padding: calc(100vw * (6 / 375)) calc(100vw * (30 / 375));
    margin-bottom: calc(100vw * (20 / 375));
  }
  .expert-detail .text-wrap .bottom {
    margin-bottom: calc(100vw * (40 / 375));
    max-height: calc(100vw * (230 / 375));
  }
  .expert-detail .name-wrap {
    margin-bottom: calc(100vw * (20 / 375));
  }
  .expert-detail .name-wrap .title {
    font-size: calc(100vw * (36 / 375));
    letter-spacing: calc(100vw * (10 / 375));
  }
  .expert-detail .name-wrap .sub-title {
    font-size: calc(100vw * (18 / 375));
  }

  .expert-detail .scroll-item {
    gap: calc(100vw * (20 / 375));
  }
  .scroll-item + .scroll-item {
    margin-top: calc(100vw * (30 / 375));
  }
  .expert-detail .scroll-item .category {
    font-size: calc(100vw * (26 / 375));
  }
  .expert-detail .resume-item {
    padding-left: calc(100vw * (11 / 375));
  }
  .expert-detail .resume-item::before {
    content: "";
    display: block;
    width: calc(100vw * (5 / 375));
    height: calc(100vw * (5 / 375));
    border-radius: 50%;
    margin-right: calc(100vw * (10 / 375));
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .resume-item + .resume-item {
    margin-top: calc(100vw * (12 / 375));
  }

  .expert-detail.example .example-inner {
    max-width: 100%;
    margin-top: calc(100vw * (60 / 375));
    padding: 0 calc(100vw * (20 / 375));
  }
  .expert-detail.example .example-inner .head {
    flex-direction: column;
    align-items: flex-start;
    gap: calc(100vw * (10 / 375));
  }
  .expert-detail.example .example-inner .head .title {
    font-size: calc(100vw * (26 / 375));
    line-height: normal;
  }
  .expert-detail.example .example-inner .btn-more {
    padding: calc(100vw * (12 / 375)) calc(100vw * (30 / 375));
    gap: calc(100vw * (30 / 375));
    font-size: calc(100vw * (16 / 375));
    margin-bottom: calc(100vw * (20 / 375));
  }
  .expert-detail.example .example-inner .swiper_wrap .swiper {
    padding: calc(100vw * (30 / 375)) calc(100vw * (40 / 375));
  }
  .expert-detail.example .example-inner .slide_btn {
    transform: translate(0, -50%);
  }
  .expert-detail.example .btn-box {
    padding-top: calc(100vw * (30 / 375));
  }
  .expert-detail.example .btn-box .btn {
    border-radius: calc(100vw * (50 / 375));
    padding: calc(100vw * (17 / 375)) 0;
    max-width: calc(100vw * (335 / 375));
  }

  .expert-detail .text-wrap .bottom::-webkit-scrollbar {
    width: 5px;
  }
  .expert-detail .text-wrap .bottom::-webkit-scrollbar-track {
    background: red;
  }
  .expert-detail .text-wrap .bottom::-webkit-scrollbar-thumb {
    -webkit-border-raidus: 8px;
    -mox-border-radius: 8px;
    border-radius: 8px;
    background: #da250b;
  }
  /* E:: 2-3 전문가 소개 */

  /* S:: 2-4 언론보도 */
  .expert-column-wrap .column-inner {
    padding-top: calc(100vw * (75 / 375));
  }
  .expert-column-wrap .column-inner .top {
    padding-bottom: 0;
  }
  .expert-column-wrap .column-inner .bottom {
    padding: calc(100vw * (40 / 375)) 0 0 0;
  }
  /* E:: 2-4 언론보도 */

  /* S:: 2-4 언론보도 - 에디트 */
  .expert-editor-wrap {
    padding-bottom: calc(100vw * (40 / 375));
    margin-bottom: calc(100vw * (40 / 375));
  }
  .expert-editor-wrap .editor-head {
    padding: calc(100vw * (75 / 375)) calc(100vw * (20 / 375))
      calc(100vw * (30 / 375));
    margin-bottom: calc(100vw * (30 / 375));
  }
  .expert-editor-wrap .editor-head .head-inner {
    margin: 0 calc(100vw * (20 / 375));
  }
  .expert-editor-wrap .editor-head .float-type {
    font-size: calc(100vw * (14 / 375));
    padding: calc(100vw * (5 / 375)) calc(100vw * (20 / 375));
    margin-bottom: calc(100vw * (20 / 375));
  }
  .expert-editor-wrap .editor-head .head-box {
    gap: calc(100vw * (20 / 375));
    flex-direction: column;
  }
  .expert-editor-wrap .editor-head .title {
    font-size: calc(100vw * (18 / 375));
    margin-bottom: calc(100vw * (15 / 375));
  }
  .expert-editor-wrap .editor-head .sub-title {
    font-size: calc(100vw * (14 / 375));
  }
  .expert-editor-wrap .editor-head .img-box {
    max-width: 100%;
  }
  .expert-editor-wrap .editor-box {
    min-height: calc(100vw * (546 / 375));
  }
  .other-list .btn-box {
    padding-top: calc(100vw * (30 / 375));
  }
  .other-list .btn-box .btn {
    padding: calc(100vw * (18 / 375)) 0;
    max-width: calc(100vw * (335 / 375));
    border-radius: 50px;
  }

  /* E:: 2-4 언론보도 - 에디트 */

  /* S:: 2-5 오시는 길 */
  .expert-map {
    padding-top: calc(100vw * (75 / 375));
    min-height: auto;
  }
  .expert-map::before {
    content: "";
    display: block;
    width: calc(100vw * (100 / 375));
    height: calc(100vw * (120 / 375));
    left: 0;
    background: url("/src/resources/image/intro/map_bg.jpg") no-repeat center /
      calc(100vw * (100 / 375)) calc(100vw * (120 / 375));
    transform: translateY(0);
  }
  .expert-map .map-contents {
    min-height: calc(100vw * (274 / 375));
    margin-bottom: calc(100vw * (30 / 375));
  }
  .expert-map .map-contents .map {
    width: 100%;
    height: calc(100vw * (234 / 375));
  }
  .expert-map .map-inner {
    padding: 0 calc(100vw * (20 / 375));
  }
  .expert-map .map-item .map-item-inner {
    font-size: calc(100vw * (16 / 375));
    line-height: calc(100vw * (24 / 375));
    padding: calc(100vw * (10 / 375)) 0;
  }
  .expert-map .map-info-item {
    padding: calc(100vw * (20 / 375)) 0;
    flex-direction: column;
  }
  .expert-map .map-info-item .map-text {
    flex-direction: column;
    gap: calc(100vw * (10 / 375));
    font-size: calc(100vw * (16 / 375));
    line-height: calc(100vw * (24 / 375));
    align-items: flex-start;
  }
  .map-text + .map-text {
    margin-top: calc(100vw * (15 / 375));
  }
  .expert-map .map-info-item .map-text .title {
    min-width: 100%;
  }
  .expert-map .map-info-item .map-text:last-of-type {
    max-width: 100%;
  }
  /* E:: 2-5 오시는 길 */

  /* S:: 6-1 의뢰인 후기 */
  .review-wrap {
    padding-top: calc(100vw * (75 / 375));
  }
  .review-wrap .review-top {
    padding-bottom: calc(100vw * (10 / 375));
  }
  /* 상세 */
  /* E:: 6-1 의뢰인 후기 */

  /* S:: 6-2 유튜브 */
  .youtube-wrap {
    padding-top: calc(100vw * (75 / 375));
  }
  .youtube-wrap .youtube-top {
    padding-bottom: calc(100vw * (40 / 375));
  }
  .youtube-wrap .best-wrap {
    gap: calc(100vw * (15 / 375));
    padding: calc(100vw * (15 / 375)) calc(100vw * (20 / 375)) 0;
    flex-direction: column;
  }

  .youtube-wrap .best-wrap .img-box-container {
    max-width: calc(100vw * (640 / 375));
  }
  .youtube-wrap .best-wrap .img-box {
    width: 100%;
    height: calc(100vw * (212 / 375));
  }
  .youtube-wrap .text-wrap .title {
    font-size: calc(100vw * (20 / 375));
    margin-bottom: calc(100vw * (10 / 375));
  }
  .youtube-wrap .text-wrap .sub-title {
    font-size: calc(100vw * (14 / 375));
    line-height: 140%;
    margin-bottom: calc(100vw * (30 / 375));
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .youtube-wrap .youtube-bottom {
    max-width: calc(100vw * (1500 / 375));
    padding: calc(100vw * (40 / 375)) 0 0 0;
  }
  .category-item + .category-item {
    margin-top: calc(100vw * (40 / 375));
  }
  /* E:: 6-2 유튜브 */

  /* S:: 6-3 컬럼 */
  .story-column-wrap .column-inner {
    padding-top: calc(100vw * (75 / 375));
  }
  .story-column-wrap .column-inner .top {
    padding-bottom: calc(100vw * (10 / 375));
    margin-bottom: calc(100vw * (30 / 375));
  }
  .story-column-wrap .column-inner .bottom {
    padding: 0;
  }
  .story-column-wrap .column-inner .bottom .column-list-container {
    padding-top: calc(100vw * (30 / 375));
  }
  /* E:: 6-3 컬럼 */

  /* E:: 4 상활별 대응 전략 */
  .page .situation {
    word-wrap: break-word;
    word-break: keep-all;
  }
  .page .situation .text-wrap .heading-text {
    margin-top: calc(100vw * (-10 / 375));
    white-space: break-spaces;
  }
  .page .situation .text-wrap .heading-text .title {
    word-wrap: break-word;
    word-break: keep-all;
  }
  .page .situation .text-wrap .heading-text .sub-title {
    display: none;
  }
  .page .banner-main.medication {
    height: calc(100vw * (360 / 375));
    background-size: cover;
  }
  .page .banner-main.sales {
    height: calc(100vw * (360 / 375));
  }
  .page .banner-main.smuggling {
    height: calc(100vw * (360 / 375));
  }
  .page .banner-main.arrest {
    height: calc(100vw * (360 / 375));
  }

  .page .banner-main.medication {
    background: url("/src/resources/image/situation/mb-situation-medication-banner.jpg")
      no-repeat;
    background-size: cover;
  }
  .page .banner-main.sales {
    background: url("/src/resources/image/situation/mb-situation-sales-banner.jpg")
      no-repeat;
    background-size: cover;
  }
  .page .banner-main.smuggling {
    background: url("/src/resources/image/situation/mb-situation-smuggling-banner.jpg")
      no-repeat;
    background-size: cover;
  }
  .page .banner-main.arrest {
    background: url("/src/resources/image/situation/mb-situation-arrest-banner.jpg")
      no-repeat;
    background-size: cover;
  }

  /* 1번째 직통 상담 섹션 */
  .situation .apply-inner {
    height: auto;
    min-height: calc(100vw * (1116 / 375));
    background: url("/src/resources/image/situation/mb-situation-bg.jpg")
      no-repeat;
    margin-bottom: 0;
    background-size: cover;
    padding-bottom: calc(100vw * (80 / 375));
  }
  .situation .contents-wrap {
    padding: calc(100vw * (75 / 375)) calc(100vw * (20 / 375)) 0;
  }
  .situation .info-box {
    height: auto;
    flex-direction: column;
  }
  .situation .info-box .text-wrap {
    width: 100%;
    padding: calc(100vw * (48 / 375)) calc(100vw * (20 / 375));
    position: relative;
    word-wrap: break-word;
    word-break: keep-all;
  }
  .situation .info-box .img-wrap {
    border-radius: 0 0 7px 7px;
    overflow: hidden;
  }
  .situation .info-box .title {
    font-size: calc(100vw * (24 / 375));
    padding-bottom: calc(100vw * (15 / 375));
    margin-bottom: calc(100vw * (15 / 375));
  }
  .situation .info-box .sub-title {
    font-size: calc(100vw * (16 / 375));
    line-height: normal;
  }
  .situation .info-box .sub-title:last-of-type {
    margin-bottom: calc(100vw * (20 / 375));
  }
  .situation .step-box {
    font-size: calc(100vw * (16 / 375));
    gap: calc(100vw * (15 / 375));
  }
  .situation .step-box .step {
    gap: calc(100vw * (10 / 375));
  }
  .situation .step-box .text {
    letter-spacing: -0.4px;
  }

  .situation .bottom-info {
    padding-top: calc(100vw * (50 / 375));
  }
  .situation .bottom-info .title {
    font-size: calc(100vw * (26 / 375));
    margin-bottom: calc(100vw * (15 / 375));
  }
  .situation .bottom-info .sub-title {
    font-size: calc(100vw * (14 / 375));
    margin-bottom: calc(100vw * (30 / 375));
  }
  .situation .bottom-info .btn {
    max-width: 100%;
    padding: calc(100vw * (17 / 375)) 0;
    font-size: calc(100vw * (16 / 375));
    border-radius: 50px;
  }

  .situation .focus-inner .contents-wrap {
    padding-top: calc(100vw * (80 / 375));
    padding-bottom: calc(100vw * (60 / 375));
  }
  /* S:: situation common */
  .situation .head-title {
    font-size: calc(100vw * (26 / 375));
    line-height: 150%;
    margin-bottom: calc(100vw * (30 / 375));
  }
  .situation .head-title .light {
    font-size: calc(100vw * (20 / 375));
    display: inline-block;
    line-height: 140%;
    margin-top: calc(100vw * (10 / 375));
  }
  .situation .head-title .sub-title {
    font-size: calc(100vw * (18 / 375));
    font-weight: normal;
  }
  /* E:: situation common */

  .situation .img-step-list {
    gap: calc(100vw * (15 / 375));
    margin-bottom: calc(100vw * (30 / 375));
    flex-direction: column;
  }
  .situation .img-step-item {
    max-width: 100%;
    padding: calc(100vw * (10 / 375)) calc(100vw * (10 / 375))
      calc(100vw * (15 / 375)) calc(100vw * (10 / 375));
  }
  .situation .img-step-item .top {
    margin-bottom: calc(100vw * (27 / 375));
  }
  .situation .img-step-item .badge {
    font-size: calc(100vw * (14 / 375));
    padding: calc(100vw * (4 / 375)) calc(100vw * (30 / 375));
  }
  .situation .focus-inner .bottom .text {
    font-size: calc(100vw * (16 / 375));
    line-height: 140%;
  }

  .situation .focus-inner .way-text .title {
    font-size: calc(100vw * (24 / 375));
    line-height: 150%;
  }
  .situation .focus-inner .gradient-line {
    width: calc(100vw * (3 / 375));
    height: calc(100vw * (50 / 375));
    margin-bottom: calc(100vw * (10 / 375));
  }
  .situation .focus-inner .way-text .sub-title {
    font-size: calc(100vw * (18 / 375));
    margin-bottom: calc(100vw * (20 / 375));
  }
  .situation .focus-inner .text-banner {
    width: 100%;
    padding: calc(100vw * (9 / 375)) 0;
    font-size: calc(100vw * (26 / 375));
    line-height: 140%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .situation .process-inner {
    padding-top: calc(100vw * (60 / 375));
    max-width: 100%;
  }
  .situation .process-list {
    flex-direction: column;
  }
  .situation .process-item {
    height: calc(100vw * (222 / 375));
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
  }
  .situation .process-item .float-img-box {
    background-position: center center;
  }
  .situation .process-item .float-img-box::before {
    display: none;
  }
  .situation .process-item .text-wrap {
    font-size: calc(100vw * (26 / 375));
    margin-bottom: 0;
  }
  .situation .process-item .text-wrap .count {
    margin-bottom: calc(100vw * (20 / 375));
  }
  .situation .process-item .text-wrap .title {
    margin-bottom: calc(100vw * (20 / 375));
  }
  .situation .process-item:hover .text-wrap .more-btn {
    display: none;
  }

  .situation .process-item.fix-pop {
    z-index: 10;
  }
  .situation .fix-pop-inner {
    flex-direction: column;
  }
  .situation .fix-pop-inner .img-box {
    position: relative;
    width: 100%;
    height: calc(100vw * (222 / 375));
    margin-bottom: calc(100vw * (15 / 375));
  }
  .situation .fix-pop-inner .img-box::after {
    background: linear-gradient(
      360deg,
      rgb(17 17 17 / 100%) 2%,
      transparent 130%
    );
    transform: translateX(0);
  }
  .situation .fix-pop-inner .img-box > img {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .situation .fix-pop-inner .text-wrap {
    max-width: 100%;
    margin: 0;
    padding: 0 calc(100vw * (20 / 375)) calc(100vw * (10 / 375))
      calc(100vw * (20 / 375));
    justify-content: flex-start;
  }
  .situation .fix-pop-inner .text-wrap .title {
    margin: 0;
  }
  .situation .fix-pop-inner .heading {
    gap: calc(100vw * (10 / 375));
    padding-bottom: calc(100vw * (10 / 375));
    margin-bottom: calc(100vw * (15 / 375));
  }
  .situation .fix-pop-inner .heading .count {
    padding: calc(100vw * (5 / 375)) calc(100vw * (10 / 375));
    font-size: calc(100vw * (18 / 375));
    line-height: normal;
    margin: 0;
  }
  .situation .fix-pop-inner .emphasize-box {
    padding: calc(100vw * (5 / 375)) calc(100vw * (7 / 375));
    margin-bottom: calc(100vw * (15 / 375));
    font-size: calc(100vw * (12 / 375));
    word-wrap: break-word;
    word-break: keep-all;
  }
  .situation .fix-pop-inner .emphasize-box br {
    display: none;
  }
  .situation .fix-pop-inner .desc-list {
    gap: calc(100vw * (15 / 375));
  }
  .situation .desc-item {
    flex-direction: column;
    gap: calc(100vw * (10 / 375));
    font-size: calc(100vw * (20 / 375));
  }
  .situation .desc-item .title-box {
    width: auto;
  }
  .situation .desc-item .sub-title-box {
    line-height: 150%;
    font-size: calc(100vw * (14 / 375));
  }
  .situation .desc-item .sub-title-box p {
    font-size: calc(100vw * (14 / 375));
  }
  .situation .desc-item .sub-title {
    gap: calc(100vw * (5 / 1520));
  }
  .situation .desc-item .in.sub-title {
    padding-left: calc(100vw * (30 / 375));
  }
  .situation .fix-pop-inner .close-btn {
    font-size: calc(100vw * (18 / 375));
    transform: translate(calc(100vw * (-20 / 375)), calc(100vw * (20 / 375)));
    gap: calc(100vw * (5 / 375));
  }

  .situation .dialog-inner {
    background: url("/src/resources/image/situation/mb-dialog-bg.jpg") no-repeat;
    padding-top: calc(100vw * (80 / 375));
    background-size: auto calc(100vw * (577 / 375));
    background-position: center top;
  }
  .situation .dialog-inner .head-title {
    margin-bottom: calc(100vw * (15 / 375));
    word-wrap: break-word;
    word-break: keep-all;
    line-height: 1;
  }
  .situation .dialog-inner .head-title .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(100vw * (15 / 375));
    line-height: 110%;
  }

  .situation .dialog-container {
    padding: 0 calc(100vw * (10 / 375));
  }
  .situation .dialog-box {
    width: calc(100vw * (325 / 375));
    height: calc(100vw * (325 / 375));
  }
  .situation .dialog-list {
    width: calc(100vw * (290 / 375));
    height: calc(100vw * (290 / 375));
  }

  .dialog-text .count {
    font-size: calc(100vw * (28 / 375));
  }
  .dialog-text .title {
    font-size: calc(100vw * (16 / 375));
  }

  .situation .dialog-box .dialog-logo {
    width: calc(100vw * (118 / 375));
    height: calc(100vw * (118 / 375));
  }
  .situation .dialog-box .dialog-logo img {
    width: calc(100vw * (42 / 375));
  }
  .situation .triangle-img {
    width: calc(100vw * (40 / 375));
    transform: translateY(160%);
  }

  .situation .dialog-inner .text-wrap {
    padding: 0 calc(100vw * (20 / 375));
    margin: calc(100vw * (72 / 375)) auto 0 auto;
  }
  .situation .dialog-inner .text-wrap .heading {
    font-size: calc(100vw * (18 / 375));
    padding-bottom: calc(100vw * (20 / 375));
    margin-bottom: calc(100vw * (20 / 375));
  }

  .situation .dialog-inner .count-list {
    gap: calc(100vw * (30 / 375));
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .situation .dialog-inner .count-item {
    flex: 1 1 auto;
  }
  .situation .dialog-inner .count-item .badge {
    font-size: calc(100vw * (18 / 375));
    padding: calc(100vw * (5 / 375)) calc(100vw * (10 / 375));
    margin-bottom: calc(100vw * (10 / 375));
  }
  .situation .dialog-inner .count-item .title {
    font-size: calc(100vw * (18 / 375));
    margin-bottom: calc(100vw * (10 / 375));
  }
  .situation .dialog-inner .count-item .sib-title {
    font-size: calc(100vw * (16 / 375));
    line-height: 150%;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: break-spaces;
  }
  .situation .dialog-inner .count-item .sib-title.emphasize {
    margin-top: calc(100vw * (30 / 375));
    font-size: calc(100vw * (14 / 375));
    line-height: normal;
  }
  /* E:: 4 상활별 대응 전략 */

  /* --------------------------------------- */
  /* --------------------------------------- */
  /* COMPONENT ----------------------------- */
  /* --------------------------------------- */

  /* S:: COMPONENT - TAB*/
  .history-navi-wrap {
    padding: 0 calc(100vw * (20 / 375));
  }
  .history-navi-wrap .history-box {
    top: calc(100vw * (-15 / 375));
    padding: 0;
  }
  .history-navi-wrap .history-box .history-list {
    gap: calc(100vw * (5 / 375));
  }
  .history-navi-wrap .history-box .history-item {
    font-size: calc(100vw * (12 / 375));
  }
  .history-navi-wrap .history-box .history-item img {
    width: calc(100vw * (12 / 375));
  }
  .history-navi-wrap .navi-box {
    position: relative;
  }
  .history-navi-wrap .navi-box .navi-item {
    padding: calc(100vw * (30 / 375)) 0;
  }
  .history-navi-wrap .navi-box .navi-item .link {
    font-size: calc(100vw * (18 / 375));
    line-height: calc(100vw * (21 / 375));
  }
  .history-navi-wrap .navi-box .navi-item .active.link {
    font-weight: 500;
  }
  .history-navi-wrap .navi-box .navi-item .active.link::after {
    display: none;
  }

  .history-navi-wrap .navi-box .tit {
    display: flex;
    padding: calc(100vw * (15 / 375)) calc(100vw * (20 / 375));
    position: relative;
    font-size: calc(100vw * (18 / 375));
    font-weight: 800;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .history-navi-wrap .navi-box .tit:before {
    width: calc(100vw * (7 / 375));
    height: calc(100vw * (7 / 375));
    content: "";
    background: #fff;
    border-radius: 50%;
    transform: none;
  }
  .history-navi-wrap .navi-box .img-arrow {
    width: calc(100vw * (20 / 375));
    margin-left: auto;
    transform: rotate(90deg);
    transition: 0.5s;
  }
  .history-navi-wrap .navi-box .img-arrow.active {
    transform: rotate(270deg);
  }
  .history-navi-wrap .navi-box .navi-list {
    flex-direction: column;
    position: absolute;
    top: 100%;
    z-index: 10;
    background-color: rgba(218, 37, 11, 0.95);
    width: 100%;
    align-items: flex-start;
    padding-left: 20px;
    display: none;
  }
  .history-navi-wrap .navi-box .navi-list.mb-active {
    display: flex;
  }
  /* E:: COMPONENT - TAB*/

  /* S:: COMPONENT - SEARCH_INPUT */
  .search-box {
    border-radius: calc(100vw * (50 / 375));
    max-width: calc(100vw * (335 / 375));
    margin: 0 auto;
  }
  .search-input {
    padding: calc(100vw * (16 / 375)) calc(100vw * (20 / 375))
      calc(100vw * (15 / 375)) calc(100vw * (20 / 375));
  }
  .search-svg {
    padding-right: calc(100vw * (24 / 375));
  }
  /* E:: COMPONENT - SEARCH_INPUT */
}
